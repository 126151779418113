import React from 'react'

const LinkedInIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.7 4.67"
      version="1.1"
    >
      <g transform="translate(-124.72333,-125.24701)">
        <path
          d="m 124.72333,125.56526 c 0,-0.18293 0.15315,-0.33141 0.34193,-0.33141 h 3.94265 c 0.18886,0 0.34193,0.14848 0.34193,0.33141 v 3.96374 c 0,0.18298 -0.15307,0.33136 -0.34193,0.33136 h -3.94265 c -0.18877,0 -0.34193,-0.14836 -0.34193,-0.3313 v -3.96386 z"
          fill="#666666"
        />
        <path
          d="m 126.11994,128.73732 v -2.08287 h -0.69232 v 2.08287 h 0.69239 z m -0.34601,-2.3672 c 0.24137,0 0.39164,-0.15994 0.39164,-0.35982 -0.004,-0.20444 -0.15027,-0.35991 -0.38705,-0.35991 -0.23695,0 -0.3917,0.15547 -0.3917,0.35989 0,0.19988 0.15022,0.35982 0.38257,0.35982 h 0.004 z m 0.72921,2.3672 h 0.69226 v -1.16305 c 0,-0.0622 0.004,-0.12449 0.0228,-0.1689 0.05,-0.12443 0.16393,-0.25323 0.35523,-0.25323 0.25045,0 0.35069,0.19099 0.35069,0.47102 v 1.11416 h 0.69224 v -1.19425 c 0,-0.63975 -0.34149,-0.93745 -0.79697,-0.93745 -0.37343,0 -0.53743,0.20871 -0.62855,0.35087 h 0.005 v -0.30197 h -0.69227 c 0.009,0.1954 -6e-5,2.08287 -6e-5,2.08287 z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export default LinkedInIcon
