import React from 'react'

const StravaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" version="1.1">
      <g transform="translate(-18.770907,-6.6709066)" id="Edges" />
      <g transform="translate(-18.770907,-6.6709066)" id="Background">
        <path
          fill="#666666"
          d="m 63.485915,45.878228 c 0,3.249179 -2.631645,5.880824 -5.880823,5.880824 H 24.381767 c -3.249179,0 -5.880823,-2.631645 -5.880823,-5.880824 V 12.645403 c 0,-3.2491787 2.631644,-5.8808233 5.880823,-5.8808233 h 33.223325 c 3.249178,0 5.880823,2.6316446 5.880823,5.8808233 z"
        />
        <path
          fill="#fff"
          d="m 45.772147,38.356335 -3.047188,-6.0025 h -4.469061 l 7.516249,14.833439 7.511875,-14.833439 h -4.473438"
        />
        <path
          fill="#fff"
          d="m 38.590584,24.187899 4.136563,8.16375 h 6.083437 l -10.22,-20.164375 -10.206875,20.166561 h 6.079064"
        />
      </g>
    </svg>
  )
}

export default StravaIcon
